/* Import fonts and global styles into index.js file */

@font-face {
  font-family: 'Neucha';
  src: url('../../public/fonts/Neucha-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../public/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../public/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../public/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Pacifico';
  src: url('../../public/fonts/Pacifico-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//** GLOBAL STYLES */
@import 'app';
@import 'toggle';
@import 'success-error';
@import 'global-buttons';
