/* Styles for error and success messages */

.error-message,
.success-message {
  padding-top: 1em;
  font-size: small;
}
.error-message {
  color: rgb(154, 0, 0);
}

.success-message {
  color: rgb(18, 96, 2);
}

#login-signup-error {
  max-width: 22em;
}
